<template>
  <div>
    <v-menu
      offset-x
      offset-y
      :close-on-content-click="false"
      content-class="custom-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="allColumnsBtn"
          v-on="on"
        >
          <span>All Columns</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in getCardOptions"
          :key="index"
        >
          <v-list-item-title>
            <v-checkbox
              v-model="item.status"
              value="on"
              :label="item.card_name"
              @change="onChangeCardStatus(item)"
            />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    tenantTech: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dashboard/getIsLoading",
      selectedCards: "dashboard/getSelectedCards",
      getCardOptions: "dashboard/getCardOptions",
    }),
  },
  methods: {
    async onChangeCardStatus(item) {
      const params = {};
      params.card_id = item.id;
      params.user_card_id = item.card_id;
      params.status = item.status || "off";
      params.section = item.section;
      params.card_color_code = item.card_color_code;
      params.sort_order = item.sort_order;
      params.vehicle_tech = item.vehicle_tech;
      params.tenant_tech = this.tenantTech;
      let card = item.card_name;
      if (item.status == "on") {
        if (item.section == "section_1" || item.section == "section_4") {
          card = item.section;
        }
        this.$emit("change", "cardOption", card);
      }
      await this.$store.dispatch("dashboard/updateCard", params);
    },
  },
};
</script>
<style lang="sass" scoped>
.v-menu__content
  height: 300px
  border-radius: 8px
  margin-top: 8px
  right: 35px !important
  width: 205px !important
  left: unset !important
  .v-list
    .v-list-item
      padding: 0 0px !important
      min-height: unset !important
      margin-bottom: 4px
      &:hover
        background: #f1f1f1
      .v-list-item__title
        line-height: 100% !important
        font-weight: 300
        font-size: 12px !important

.allColumnsBtn
  background: #e7e7e7 !important
  border: 1px solid #DADADA !important
  border-radius: 6px !important
  padding: 0.4rem 0.6rem !important
  text-transform: unset !important
  display: flex !important
  box-shadow: 0 0 0 0 !important
  min-width: unset !important
  font-size: 0.875rem
  color: #000 !important
  text-transform: unset !important
  font-family: 'Poppins Medium'
  margin-right: 0 !important
  .v-btn__content
    position: relative
    span
      position: relative
      padding-right: 17px
      &:after
        content: "\F0140"
        font: normal normal normal 24px / 1 "Material Design Icons"
        position: absolute
        right: -3px
        padding: 0
        font-size: 17px
        top: 1px
.drawerOpen
  .v-menu__content
    right: 22px !important
</style>
